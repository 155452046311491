<template>
    <div class="auth-view">


        <div class="input-box" v-if="needCaptcha">
            <div class="cell " style="margin-top: 3vmin;">
                <div class="val" style="line-height: 10vmin;text-align: right;">验证码： </div>
                <div class="label">
                    <input class="resident-id sc-input" type="text" maxlength="4" v-model="captcha"/>
                </div>
                <div class="val">
                    <img :src="src" alt="验证码" style=" height: 10vmin;margin-left: 5px;" />
                </div>
            </div>
            <el-button class="btn-auth" type="primary" round @click="captchaVerify">确 定</el-button>
        </div>
        <Loading v-show="showAuthLoading && !needCaptcha" />

      <div style="width: 100%">
        <div class="sys-title">
          <div class="name">云胶片</div>
          <div class="about">——随身携带，随时查阅</div>
        </div>

        <div class="input-box" v-if="needAuthResidentId && !needCaptcha">

            <div class="auth-hint">为了您的数据安全，请输入身份证号后4位！</div>
            <div class="input-row input-row">
                <input class="resident-id sc-input" type="text" maxlength="4" v-model="verifyValue"/>
            </div>
            <el-button class="btn-auth" type="primary" round @click="getVerify">确 定</el-button>
        </div>
        <div class="input-box" v-if="needAuthPatientName && !needCaptcha">

            <div class="auth-hint">为了您的数据安全，请输入姓名确认！</div>
            <div class="input-row" style="display: flex; align-items: center; justify-content: center">
                <input class="patient-xing sc-input" type="text" v-model="verifyValue"/>
                <span class="patient-ming">{{patientLastName}}</span>
            </div>
            <!-- <el-input class="input-patient-name" placeholder="输入就诊人姓名" v-model="patientName" clearable></el-input> -->
            <el-button class="btn-auth" type="primary" round @click="getVerify">确 定</el-button>
        </div>

      </div>

      <div v-if="verifyModeError" class="verifyModeError">
        {{ verifyModeError }}
      </div>
      <div v-if="verifyModeNoStudy" class="verifyModeNoStudy">
        {{ verifyModeNoStudy }}
      </div>

    </div>
</template>

<script>
import { store } from '../../utils/store'
import { MessageProxy } from '../../utils/MessageProxy'

import Loading from '../LoadingBlue.vue'
export default {
    name: 'IdentityCheck',
    components: {
        Loading,
    },
    data() {
        return {
            captcha: '',
            needCaptcha: window.config.needCaptcha,
            needAuth: false,
            needAuthResidentId: false,
            needAuthPatientName: false,
            showAuthLoading: window.config.needCaptcha,
            showHistory: false,
            src:'',
            verifyValue: '',
            hospital: { // 改检查的医院信息
                // shortName: '',
                // id: '',
                enableFlag: true,
                identityCheckMode : 'idCard'
            },
            verifyModeError: '',
            verifyModeNoStudy: ''
        }
    },
    async created() {
        await this.init();


    },
    methods: {
        async init () { // 初始化
            // 微信公众号进入
            if (store.userId != undefined && store.userId != '' && location.pathname.endsWith('/index.html')) {
                store.isAuthenticated = true;
            }

            if (false == store.isAuthenticated) {
                this.needAuth = true;
            } else {
                this.needAuth = false;
            }

            if (store.hospital.image3dServiceUrl != undefined && store.hospital.image3dServiceUrl != '') {
                this.showImage3dButton = true;
            }

            this.hospital.enableFlag = store.hospital.enableFlag;
            this.hospital.identityCheckMode = store.hospital.identityCheckMode;

            //此处解析 getStudyIdByShortCode=4a3FcB、getStudyIdByToken=*** 这种路径
            const arr = this.$route.params.studyID.split('=')
            if(arr.length === 2) {
                await MessageProxy[arr[0]](arr[1], store.hospital.shortName).then((result) => {
                    if (true == result.hasError) {
                        this.$message({
                            message: result.errorText,
                            type: 'error',
                            showClose: true,
                        });
                        return;
                    }
                    if (result.data != null && result.data.code != '200') {
                        this.$message({
                            message: result.data.message,
                            type: 'error',
                            showClose: true,
                        });
                        return;
                    }
                    if (result.data.data && result.data.data.studyId) {
                        this.studyID = result.data.data.studyId;
                    } else {
                        this.$message({
                            message: '获取数据失败。',
                            type: 'error',
                            showClose: true,
                        });
                        return;
                    }
                })
            } else {
                this.studyID = this.$route.params.studyID;
            }
            if (!this.studyID) {
                this.$message({
                    message: 'studyID 为空',
                    type: 'error',
                    showClose: true,
                });
                return
            }

            await this.getCaptcha();
            this.JSAPIPay = store.JSAPIPay;
            this.payWay = store.payWay;

            if (!this.needCaptcha) {
                await this.getHospitalInfo();
                // if ('' == store.hospital.id || '' == store.hospital.image3dServiceUrl) {
                //     if (store.hospital.shortName != undefined && store.hospital.shortName != "") {
                //         await this.getHospitalInfo();
                //     }
                // }
                await this.getVerifyMode();

            }
        },
        // 生成校验验证码
        async getCaptcha () {
            const res = await  MessageProxy.getCaptcha({
                key: this.studyID
            })
            const resBlob = await res.blob();
            const blob = new Blob([resBlob], { type: 'image/png'})
            // // 兼容 edge 不支持 createObjectURL 方法
            // if ("msSaveOrOpenBlob" in navigator) return window.navigator.msSaveOrOpenBlob(blob, tempName + fileType);
            let blobUrl = URL.createObjectURL(blob)
            this.src = blobUrl;

            // // 下载
            // const exportFile = document.createElement("a");
            // exportFile.style.display = "none";
            // exportFile.download = `11.png`; // `${tempName}.${fileType}`
            // exportFile.href = blobUrl;
            // document.body.appendChild(exportFile);
            // exportFile.click();
            // // 去除下载对 url 的影响
            // document.body.removeChild(exportFile);
            // window.URL.revokeObjectURL(blobUrl);
        },
        // 校验验证码是否正确
        async captchaVerify  () {
            if (!this.captcha) {
                this.$message("请输入验证码")
                return
            }
            const res = await  MessageProxy.captchaVerify({
                key: this.studyID,
                captcha: this.captcha
            })
            if (!res.data || !res.data.data || !res.data.data.records || res.data.code != '0') {
                this.$message({
                    message: res.data.message,
                    type: 'error',
                    showClose: true,
                });
                return;
            }
            this.needCaptcha = false;
            localStorage.setItem("sessionID", res.data.data.records);

            if ('' == store.hospital.id || '' == store.hospital.image3dServiceUrl) {
                if (store.hospital.shortName != undefined && store.hospital.shortName != "") {
                    await this.getHospitalInfo();
                }
            }
        },

        getVerifyMode() {
            this.verifyModeError = '';
            MessageProxy.getVerifyMode(store.userId, store.hospital.shortName, this.studyID).then((result)=>{
                if (true == result.hasError) {
                    this.$message({
                        message: result.errorText,
                        type: 'error',
                        showClose: true,
                    });
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    if (result.data.code == '515') {
                        this.verifyModeError = '网络出现故障，请稍后重试或联系管理员';
                        return;
                    }
                    if (result.data.code == '406') {
                        this.verifyModeNoStudy = '未查询到检查或报告未正式发布，请稍后重试';
                        return;
                    }
                }
                this.pageShow = true
                // "patientName": "*试",
                // "mode": "name" //mode 这个字段取值有俩种 idCard / name
                // idCard 直接让他输入后四位就可以了
                // 如果是 name 会给出名字的提示 patientName 这个字段
                //显示验证界面
                this.showAuthLoading = false;
                if (result.data.data.mode == 'idCard') {
                    this.needAuthResidentId = true;
                    this.needAuthPatientName = false;
                } else{
                    this.needAuthResidentId = false;
                    this.needAuthPatientName = true;
                    this.patientLastName = result.data.data.patientName;
                }
            })
        },
        getVerify () {
            if ('' == this.verifyValue) {
                this.$message.error(this.needAuthResidentId ? "请输入身份证号后四位！" : '请输入就诊人姓名的第一个字！');
                return;
            }
            MessageProxy.getVerify(store.userId, store.hospital.shortName, this.studyID, this.verifyValue).then((result)=>{
                if (true == result.hasError) {
                    this.$message({
                        message: result.errorText,
                        type: 'error',
                        showClose: true,
                    });
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message({
                        message: result.data.message,
                        type: 'error',
                        showClose: true,
                    });
                    return;
                }
                if (result.data.desc == '验证失败') {
                    this.$message({
                        message: result.data.desc,
                        type: 'error',
                        showClose: true,
                    });
                    return
                }
                this.study = result.data.data;


                // if (this.needAuthResidentId) {
                //     if (this.study.isVIP != undefined && '1' == this.study.isVIP) {
                //         this.residentIdLast4 = this.study.patientResidentId.substr(-4);
                //     } else {
                //         this.residentIdLast4 = '';
                //     }
                // } else {
                //     if (this.study.isVIP != undefined && '1' == this.study.isVIP) {
                //         this.patientFirstName = this.study.patientName.substr(0, 1);
                //     } else {
                //         this.patientFirstName = '';
                //     }
                // }


                store.isAuthenticated = true;
                this.needAuth = false;

                if (this.needAuthResidentId) {
                    if(this.showHistory) {
                        this.$router.push({
                            path: '/studylistV2/' + this.$route.params.studyID,
                            query: {
                                ...this.$route.query,
                                patientName: this.study.patientName,
                                patientGender: this.study.patientGender,
                                patientAge: this.study.patientAge + this.study.patientAgeUnit,
                                queryKey: this.getQueryKey(this.study)
                            }
                        });
                    } else {
                        this.$router.push({
                            path: '/studyinfoDetail/' + this.$route.params.studyID,
                            query:this.$route.query
                        });
                    }

                } else {
                    const path = this.$route.fullPath.replace('/studyinfo/' , '/studyinfoDetail/')
                    this.$router.push(path);
                }

            })
        },
        async getHospitalInfo() {
            MessageProxy.getHospitalInfo(store.hospital.shortName).then((result) => {
                if (true == result.hasError) {
                    this.$message.error(result.errorText);
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message.error(result.data.message);
                    return;
                }
                // this.$message("result.image3dServiceUrl: " + result.data.data.image3dServiceUrl)
                if (result.data.data != null) {
                    store.hospital.id = result.data.data.id;
                    this.hospitalId = store.hospital.id;
                    this.showHistory = !!result.data.data.historyStudyListFlag;

                    if (result.data.data.image3dServiceUrl != null && result.data.data.image3dServiceUrl != '') {
                        store.hospital.image3dServiceUrl = result.data.data.image3dServiceUrl;
                        this.showImage3dButton = true;
                    }
                    if (result.data.data.enableFlag != undefined && 0 == result.data.data.enableFlag) {
                        store.hospital.enableFlag = false;
                    }

                    if (result.data.data.identityCheckMode != undefined && '' != result.data.data.identityCheckMode) {
                        store.hospital.identityCheckMode = result.data.data.identityCheckMode;
                    }

                    this.hospital.enableFlag = store.hospital.enableFlag;
                    this.hospital.identityCheckMode = store.hospital.identityCheckMode;
                    // console.log('SingleStudyView2 store.hospital.id', store.hospital.id)
                }
            })
        },
        getQueryKey(study) {
            if (study == undefined){
                return ""
            }

            if (study.patientResidentId != undefined && study.patientResidentId.length > 10){
                return study.patientResidentId
            } else if (study.inpatientId != undefined && study.inpatientId != ''){
                return study.inpatientId
            } else if (study.outpatientId != undefined && study.outpatientId != ''){
                return study.outpatientId
            }

            return ''
        },
    }
}
</script>

<style scoped>


.verifyModeError {
    height: 150px;
    width: 100%;
    background-image: url("../../assets/error_icon.svg");
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: end;
    position: absolute;
    top: 35%;
    text-align: center;
    background-position: center center;
    color: #ff8888;
}

.verifyModeNoStudy {
  height: 150px;
  width: 100%;
  background-image: url("../../assets/no-data-icon.svg");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: end;
  position: absolute;
  top: 35%;
  text-align: center;
  background-position: center center;
  color: #ff8888;
}

.cell{
    display: flex;
}

.cell>.val{
    flex: 1;
}

.auth-view {
        position:fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        backdrop-filter: blur(6px);
        background-image: linear-gradient(rgba(236, 245, 255, 0.5) 5%, rgba(255, 255, 255, 1) 15%);

        display: flex;
        justify-content: center;

    }
    .auth-view .input-box {

        padding: 8vmin;
        margin: 1vmin;
        border-radius: 1vmin;
        /* border: solid #409eff 1px; */
    }
    .auth-view .auth-hint {
        font-size: 4vmin;
      text-align: left;
      font-weight: bold;
        margin-bottom: 5vmin;
    }
    .auth-view .resident-id {
        font-size: 7.5vmin;
        text-align: center;
        width: 80vmin;
        border: none;
      text-align: center;
      letter-spacing: 15px;
    }
    .auth-view .patient-ming {
        font-size: 6.5vmin;
    }
    .auth-view .patient-xing {
        font-size: 6.5vmin;
        width: 20vmin;
      margin-right: 10px;
        text-align: center;
    }
    .auth-view .input-box .btn-auth {
        margin-top: 6vmin;
        width: 80vmin;
      border-radius: 8px;
      font-size: 4vmin;
    }
    .sc-input {
        box-sizing: border-box;
        /* background-color:#fff; */
        color:#000;
        border: 1px solid rgba(236, 230, 255, 1) !important;
        height: 12vmin;
        line-height: 12vmin;
        padding: 0;
        outline: none;
      border-radius: 8px;
    }
    .sc-input:focus {
        border-color: #409eff !important;
    }
.auth-view{
  background:url("../../assets/codebg.jpg") no-repeat #F1F8FF;
  background-size: 100%;
}
    .auth-view .test-run {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2vmin;
        color: red;
        font-size: 3vmin;

    }
    .sys-title{
      text-align: left;
      margin-top: 20vmin;
      margin-left: 9vmin;
      margin-bottom: 15vmin;

    }

.name{
  font-size: 8vmin;
  font-weight: bold;
}
.about{
  font-size: 4vmin;
  font-weight: bold;
}

/*PC端tab样式*/
@media (min-width: 768px) {
  .sys-title{
    margin:100px 200px;

  }
  .sys-title .name{
    font-size: 50px;
  }
  .sys-title .about{
    font-size: 25px;
  }
  .auth-view .input-box{
    padding: 0px 8vmin;
  }
  .auth-view .auth-hint{
    font-size: 24px;
    text-align: center;
  }
  .auth-view .input-box .btn-auth{
    width: 600px;
    font-size: 24px;
  }
  .auth-view .resident-id{
    width: 600px;
    font-size: 60px;
  }
  .sc-input{
    height: 80px;
  }
  .auth-view .patient-xing{
    font-size: 60px;
    width: 160px;
    margin-right: 20px;
  }
  .auth-view .patient-ming{
    font-size: 60px;
  }
}

</style>
