<template>
  <div class="share-view-container">
    <!--    <div class="period-box" v-if="showPeriod">-->
    <!--      <div class="period-prompt" >有效期</div>-->
    <!--      <el-radio-group v-model="period" >-->
    <!--&lt;!&ndash;        <el-radio label="6h">6小时</el-radio>&ndash;&gt;-->
    <!--&lt;!&ndash;        <el-radio label="1d">1天</el-radio>&ndash;&gt;-->
    <!--        <el-radio label="3d">3天</el-radio>-->
    <!--        <el-radio label="7d">7天</el-radio>-->
    <!--        <el-radio label="15d">15天</el-radio>-->
    <!--        <el-radio label="30d">30天</el-radio>-->
    <!--      </el-radio-group>-->
    <!--      <div class="custom-alert" >为保证信息安全，建议设置为3天</div>-->
    <!--    </div>-->

    <!-- 提取码选项 -->
    <!--    <div class="period-prompt">提取码</div>-->
    <!--    <div class="auth-code-options">-->
    <!--      <el-radio-group v-model="authCodeOption" @change="handleAuthCodeOptionChange">-->
    <!--        <el-radio label="auto">系统生成提取码</el-radio>-->
    <!--        <el-radio label="manual">自定义提取码</el-radio>-->
    <!--      </el-radio-group>-->
    <!--      <div v-if="authCodeOption === 'manual'">-->
    <!--        <el-input-->
    <!--            v-model="customAuthCode"-->
    <!--            placeholder="请输入四位提取码，仅支持数字及英文字母"-->
    <!--            maxlength="4"-->
    <!--            pattern="[A-Za-z0-9]{4}"-->
    <!--            oninvalid="setCustomValidity('请输入四位提取码，仅支持数字及英文字母')"-->
    <!--            oninput="setCustomValidity('')"-->
    <!--        ></el-input>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 创建链接按钮 -->
    <!--    <div class="create-link-button" v-if="showCreateButton">-->
    <!--      <el-button type="primary" @click="createLink">创建分享</el-button>-->
    <!--    </div>-->

    <div class="period-box" v-if="showLink">
      <div class="qr-box">

        <div class="codeTitle"><span>01</span>
          <p>分享二维码</p></div>
        <div>
          <vue-qr ref="qrCodeComponent" :text="qrText"/>
        </div>

        <p class="yzm">验证码：<span class="highlight"> {{ this.captchaCode }} </span></p>

        <div class="qr-prompt">通过微信扫一扫二维码或将二维码截图发送给对方，便可浏览此检查报告和影像</div>
        <!--      <div class="buttons-box" v-if="showLink">-->
        <!--        <el-button class="copy-button" @click="copyUrl">复制链接</el-button>-->
        <!--      </div>-->
        <!-- 新增显示链接的区域 -->
        <div class="link-display">

          <div class="codeTitle"><span>02</span>
            <p>发送链接地址</p></div>
          <div class="codeBtn">
            <el-button type="primary" @click="copyShareCodeLink">复制分享</el-button>
          </div>
          <div class="qr-prompt">通过微信等方式，将复制的文字发送给他人，便可浏览此检查报告和影像</div>
          <!--        <p>您的链接已创建：</p>-->
          <!--        <div>-->
          <!--          <input type="text" v-model="createdLink" readonly class="styled-input"/>-->
          <!--        </div>-->

          <!--        <div>-->
          <!--          &lt;!&ndash; 提取码输入框 &ndash;&gt;-->
          <!--          <input type="text" v-model="captchaCode" readonly class="styled-input"/>-->
          <!--        </div>-->
          <!--        <div>-->
          <!--          <el-button @click="cancel">关闭</el-button>-->

          <!--        </div>-->
        </div>
        <div class="codeTitle"><span>专业</span>
          <p>影像客户端浏览</p></div>
          <div>
            <div class="share-code">{{ shareCode }}</div>
          </div>
          <div class="codeBtn">
            <el-button type="primary" @click="copyStationCodeLink">复制分享码</el-button>
          </div>
        <div class="qr-prompt">
          访问官网
          <a href="https://myfilm.cc" target="_blank">https://myfilm.cc</a> 下载工作站
        </div>
        <div class="codeTips" v-if="periodTime != ''">提示：请在 <span class="highlight">{{ this.periodTime }}</span> 前查看，否则分享将过期失效</div>
<!--          <div class="qr-prompt">下载工作站，输入上方的六位数字，可查看此检查报告和图像</div>-->
        <!--      <div class="button-container">-->
        <!--        <el-button @click="downloadFile('https://www.myfilm.cc/download/Windows-WorkStation-64.zip')">windows64位工作站</el-button>-->
        <!--        <el-button @click="downloadFile('https://www.myfilm.cc/download/Windows-WorkStation-32.zip')">windows32位工作站</el-button>-->
        <!--      </div>-->
      </div>
    </div>

  </div>
</template>

<script>
import VueQr from 'vue-qr'
import {MessageProxy} from '../../utils/MessageProxy'
import {store} from '../../utils/store'
// import { Config } from '../../utils/Utils'
export default {
  name: 'ShareCodeView',
  components: {
    VueQr
  },
  props: {
    risStudyId: String,
    hospitalId: String
  },
  data() {
    return {
      authCodeOption: 'auto', // 默认选择系统生成提取码
      customAuthCode: '', // 自定义提取码
      createdLink: '', // 新增的链接变量
      showLink: false, // 控制是否显示链接
      qrText: '',
      shareCode: "",
      period: "30d",
      showCreateButton: true, // 新增属性，默认显示按钮
      captchaCode: '',  //四位随机验证码
      periodTime: '', //过期时间
      showPeriod: true,
      //logoImg: require('../../assets/logo.png'), //中间logo的地址，require必要
    }
  },
  created() {
    this.createLink();
  },
  watch: {
    customAuthCode(newVal) {
      // 当自定义提取码改变时更新 shareCode
      if (this.authCodeOption === 'manual') {
        this.shareCode = newVal;
      }
    }
  },
  methods: {
    async getToken() {
      await MessageProxy.getToken(store.userId, this.risStudyId, this.period, this.hospitalId, this.captchaCode).then((result) => {
        if (true == result.hasError) {
          this.$message.error(result.errorText);
          return;
        }
        if (result.data != null && result.data.code != '200') {
          this.$message.error(result.data.message);
          return;
        }
        if (result.data.data != undefined &&
            result.data.data.token != undefined &&
            result.data.data.token != "") {
          // this.qrText = location.href;
          this.qrText = 'https://myfilm.cc/#/s/' + result.data.data.token // + "?pwd=" + this.captchaCode;
          this.periodTime = result.data.data.periodTime;
          this.captchaCode = result.data.data.captchaCode == null ? this.captchaCode : result.data.data.captchaCode;
        } else {
          this.$message.error('获取令牌失败。');
        }
      })
    },
    getWorkStationAuthCode() {
      MessageProxy.getAuthorizationCode(store.userId, this.risStudyId, this.period, this.hospitalId, this.captchaCode).then((result) => {
        if (true == result.hasError) {
          this.$message.error(result.errorText);
          return;
        }
        if (result.data != null && result.data.code != '200') {
          this.$message.error(result.data.message);
          return;
        }
        if (result.data.data != undefined &&
            result.data.data.authcode != undefined &&
            result.data.data.authcode != "") {
          this.shareCode = result.data.data.authcode;
        } else {
          this.$message.error('获取共享码失败。');
        }
      })
    },
    handleAuthCodeOptionChange(option) {
      if (option === 'auto') {
        this.getWorkStationAuthCode(); // 获取系统生成的提取码
      } else {
        this.customAuthCode = ''; // 清空自定义提取码
        this.shareCode = ''; // 清空当前显示的提取码
      }
    },
    async createLink() {
      await this.generateCaptcha()
      await this.getToken();
      await this.getWorkStationAuthCode();

      // 假设 getToken 和 getAuthCode 成功执行并返回了链接
      // 这里需要根据实际情况调整
      this.createdLink = this.qrText
      this.showLink = true;
      this.showCreateButton = false; // 隐藏创建链接按钮
      this.showPeriod = false;
    },

    copyShareCodeLink() {
      let txa = document.createElement('textarea')
      txa.value = '欢迎使用睿影云云胶片服务' + ' \n' +
          '浏览连接: ' + this.createdLink + ' ' + '验证码:' + this.captchaCode + ' \n' +
          '有效期:' + this.periodTime
      document.body.appendChild(txa)
      txa.select()
      document.execCommand('copy')
      document.body.removeChild(txa)
      //提示信息
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 3000
      });
    },
    copyStationCodeLink() {
      let txa = document.createElement('textarea')
      txa.value = '欢迎使用睿影云云胶片服务' + ' \n' +
          '请下载阅片程序浏览。下载地址: https://myfilm.cc' + ' \n' +
          '浏览分享码：' + this.shareCode + ' ' + '有效期:' + this.periodTime
      document.body.appendChild(txa)
      txa.select()
      document.execCommand('copy')
      document.body.removeChild(txa)
      //提示信息
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 3000
      });
    },
    // 生成验证码的方法
    async generateCaptcha() {
      const possibleChars = '0123456789'; // 排除了易混淆的字符
      let captcha = '';

      for (let i = 0; i < 4; i++) { // 修改循环次数以生成4个字符长的验证码
        captcha += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
      }

      this.captchaCode = captcha;
    },
    cancel() {
      this.showCreateButton = true;
      this.showLink = false;
      this.showPeriod = true;
    },
    downloadFile(url) {
      const a = document.createElement('a')
      a.href = url
      a.download = 'WorkStation.exe' // 下载后文件名
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click() // 点击下载
      document.body.removeChild(a) // 下载完成移除元素
    }

  }
}
</script>

<style scoped>
.share-view-container {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.share-view-container .period-box,
.share-view-container .qr-box,
.share-view-container .share-code-box {
  background-color: #fff;
  border-radius: 3vmin;
  margin: 0 5vmin 5vmin 5vmin;
  padding: 2vmin 0 0 0;
  font-size: 3.5vmin;
  text-align: center;
}


.share-view-container .period-box {
  font-size: 4vmin;
}

.share-view-container .period-prompt {
  margin-bottom: 3vmin;
}

.share-view-container .share-code {
  font-size: 7vmin;
  font-weight: bold;
  padding: 10px 0;
}

.share-view-container .buttons-box {
  margin: 0 5vmin 5vmin 5vmin;
}

.share-view-container .copy-button {
  box-sizing: border-box;
  width: 100%;
}

.validity-period {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.auth-code-options {
  margin-bottom: 20px;
}

.create-link-button {
  text-align: center;
  margin-top: 20px;
}

.auth-code-options {
  background-color: #fff;
  border-radius: 3vmin;
  margin: 0 5vmin 5vmin 5vmin;
  padding: 5vmin 0;
  font-size: 3.5vmin;
  text-align: center;
}

.el-radio-group {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.el-input {
  width: 80%;
  margin: 0 auto;
}

.styled-input {
  width: 80%;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  text-align: center;
}

.custom-alert {
  font-size: 14px; /* 调整字体大小 */
  background-color: #f0f8ff; /* 调整背景颜色 */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.highlight {
  color: #c17f50; /* 设置 periodTime 的颜色 */
}

.codeTitle {
  font-weight: bold;
  text-align: left;
  position: relative;
  height: 9vmin;
}

.codeTitle span {
  font-size: 8vmin;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #8BAAC5, rgba(238, 247, 253, 0));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  opacity: 0.5;

}

.codeTitle p {
  font-size: 4vmin;
  font-weight: bold;
  position: absolute;
  bottom: -6px;
  left: 0;
  margin: 0;

}

.yzm {
  font-size: 5vmin;
  font-weight: bold;
  margin: 0;
}

.qr-prompt {
  margin: 10px 0 0 0;
  font-size: 4vmin;
  text-align: left;
}

.codeBtn {
  margin: 20px 0;
}

.codeBtn button {
  width: 100%;
  font-size: 4vmin;
}

.codeTips {
  background: #D3AE65;
  color: #fff;
  font-size: 4vmin;
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;
  text-align: left;
}

.codeTips .highlight {
  font-weight: bold;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: space-between; /* 或者使用 space-around, space-evenly 等 */
  margin: 20px 0;
}

.button-container button {
  width: 48%; /* 调整按钮宽度以适应并排显示 */
  margin: 0 1%; /* 添加间距 */
}
</style>
