<template>
    <div style="height: 100%">
        <Loading v-show="!pageShow" />
        <div v-if="pageShow" class="study-view-container">
            <div v-html="wxPayHtml" class="form"></div>
            <div class="title-container">
                <div class="tabs-container">
                    <div class="tabs-box" ref="tabsBox">
                        <template v-for="item in tabs.arr">
                        <div class="tab-item" v-if="!item.hide" :class="[item.id, item.id == tabs.selected ?  'active' : '']"  :key="item.id" @click="tabclick(item.id)">{{item.name}}</div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="content-container">
                <div class="study-info-view" v-if="tabs.selected == 'study'">
                    <StudyInfoView :risStudyId="studyID" :study="study" v-on:gotoImage="gotoImage"/>
                </div>
                <div class="image-view" v-if="tabs.selected == 'image'">
                    <iframe
                        v-if="TowDViewerMode == 1"
                        class="image-frame"
                        frameborder="no"
                        :src="`${imageHostUrl}index.html?studyuid=${studyID}&hospitalid=${hospitalId}&token=1&header=0`"
                    ></iframe>
                    <iframe
                        v-else-if="TowDViewerMode == 2"
                        class="image-frame"
                        frameborder="no"
                        :src="`${imageProUrl}index.html?studyid=${studyID}&hospitalid=${hospitalId}&embed=1&toolwrap=no`"
                    ></iframe>
                    <div v-else>
                        <p class="displayEflimImageFlagTip">{{displayTip}}</p>
                    </div>
                    <!-- <el-button class="image-view-back" icon="el-icon-back" circle size="small" type="info" @click="tabclick('study')"></el-button> -->
                </div>
                <div class="film-view" v-if="tabs.selected == 'film'">
                    <img class="film-img" :src="`${imageUrl}film.jpg`" alt="无胶片" />
                    <!-- <iframe class="image-frame" frameborder="no" :src="`${imageUrl}ConfigFileV2.pdf`"></iframe> -->
                </div>
                <div class="share-code-view" v-if="tabs.selected == 'share'">
                    <ShareCodeView :risStudyId="studyID" :hospitalId="hospitalId" />
                </div>
                <div class="more-view" v-if="tabs.selected == 'more'">
                    <el-button
                        v-if="hospital.useImageProInsteaded"
                        @click="openImage2dPro"
                        :disabled="TowDViewerMode == 0"
                        class="btn-image2d-pro">
                        <div class="title">打开专业医学影像</div>
                        <div class="hint">浏览专业医学影像，需要消耗更多流量</div>
                    </el-button>
                    <el-button
                        v-if="showImage3dButton"
                        @click="openImage3d"
                        :disabled="TowDViewerMode == 0"
                        class="btn-image3d">
                        <div class="title">打开三维重建影像</div>
                        <div class="hint">服务端三维影像后处理</div>
                    </el-button>
                    <div v-if="TowDViewerMode == 0">
                        <p class="displayEflimImageFlagTip">{{displayTip}}</p>
                    </div>

                    <div class="image-frame-box" v-show="imageProShown || image3dShown">
                        <div class="title-container">
                            <div class="back-icon" @click="closeImageFrame()"><i class="el-icon-back"></i><span class="back-text">返回</span></div>
                            <div class="view-name">{{imageViewName}}</div>
                        </div>
                        <iframe class="imagepro-frame" v-if="imageProShown" frameborder="no" :src="`${imageProUrl}index.html?studyid=${studyID}&hospitalid=${hospitalId}&embed=1&toolwrap=no`"></iframe>
                        <iframe class="image3d-frame" v-if="showImage3dButton && image3dShown" frameborder="no" :src="`${image3dUrl}index.html?studyid=${studyID}&hospitalid=${hospitalId}`"></iframe>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import StudyInfoView from './StudyInfoView.vue'
import ShareCodeView from './ShareCodeView.vue'
import Loading from '../LoadingBlue.vue'
import { MessageProxy } from '../../utils/MessageProxy'
import { store } from '../../utils/store'
import { Config } from '../../utils/Utils'

export default {
    name: 'SingleStudyView',
    components: {
        StudyInfoView,
        ShareCodeView,
        Loading
    },
    data() {
        return {
            //displayEflimImageFlagTip: "未缴纳此检查的电子胶片费，无法浏览图像。如需浏览图像，请联系就诊科室医生。", // 未缴费提示
            displayEflimImageFlagTip: "系统升级中...", // 未缴费提示
            pageShow: false,
            studyID: '',
            JSAPIPay: '',
            wxPayHtml: '', // 支付返回dom元素
            needPay: false, // 是否支付，且未过期
            patientName: '',
            patientFirstName: '',
            patientLastName: '',
            residentIdLast4: '',
            verifyStudyId: '',
            showAuthLoading: true,
            needAuth: false,
            needAuthResidentId: false,
            needAuthPatientName: false,
            imageHostUrl: Config.imageUrl, // 影像
            imageProUrl: Config.imageProUrl, // DICOM 影像
            image3dUrl: Config.image3dUrl,   // 三维重建
            imageUrl: store.imageUrl, // 胶片
            hospitalId: store.hospital.id,
            hospital: { // 改检查的医院信息
                // shortName: '',
                // id: '',
                enableFlag: true,
                identityCheckMode:'idCard',
                isProfessional:false,
                useImageProInsteaded:false,         // 使用专业版二维，替代普通二维
            },
            study: {}, // 检查信息
            tabs: {
                selected: 'study',
                arr : [
                    {
                        id: 'study',
                        name : '检查'
                    },
                    {
                        id: 'image',
                        name : '图像',
                        hide: true,
                    },
                    // {
                    //     id: 'film',
                    //     name : '胶片'
                    // },
                    // {
                    //     id: 'share',
                    //     name : '分享',
                    //     // hide: store.wx.openid,
                    // },
                    // {
                    //     id: 'more',
                    //     name: '更多'
                    // }
                ]
            },
            goods: { // 商品
                protocol: '', // 服务协议
                selected: '',
                arr: []
            },
            imageProShown: false,
            image3dShown: false,
            showImage3dButton: false,
            imageViewName: '',
            TowDViewerMode:0,
            displayTip:""
        };
    },
    watch: {
        '$route': 'init',
        hospital:{
            handler(newVal){
                if (newVal == undefined) {
                    return
                }
                if (newVal.isProfessional == true){
                    this.tabs.arr.push({
                        id: 'more',
                        name: '高级'
                    })
                }
            },
            immediate:true,
            deep:true
        }
    },
    computed: {
    },
    async created() {
        //微信公众号进入
        if (store.userId != undefined && store.userId != '' && location.pathname.endsWith('/index.html')) {
            store.isAuthenticated = true;
        }

        if (false == store.isAuthenticated) {
            this.needAuth = true;
        } else {
            this.needAuth = false;
        }

      //处理/s/f85ec65a2a54431db187b269b7e54097 验证码:6302
      //从字符串头处理 截取到空格
      if (this.$route.params.studyID.indexOf(' ') > -1) {
        this.studyID = this.$route.params.studyID.substring(0, this.$route.params.studyID.indexOf(' '));
      } else {
        this.studyID = this.$route.params.studyID;
      }
      //获取localStorage
      const cacheKey = 'verifyResult';
      const cacheDuration = 3600000 * 4; // 1小时的有效期，单位为毫秒
      // 检查缓存是否存在且未过期
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        const { result, timestamp } = JSON.parse(cachedData);
        const now = Date.now();
        if (now - timestamp < cacheDuration) {
          if(result == this.studyID) {
            this.verifyStudyId = result;
          } else {
            const path = this.$route.fullPath.replace('/v/' , '/s/')
            this.$router.push(path);
            return
          }
        }
      }
      if(this.verifyStudyId == '' || this.verifyStudyId != this.studyID) {
          const path = this.$route.fullPath.replace('/v/' , '/s/')
          this.$router.push(path);
          return
      }

      await this.getHospitalInfoByToken()

    },
    mounted() {
    },
    destroyed() {
        // console.log("destroyed 检查")
        // window.removeEventListener('popstate', this.goBack, false);
    },
    methods: {
        async init () { // 初始化
            await MessageProxy.getStudyById(store.userId, store.hospital.shortName, store.studyID).then(async (result) => {
              if (true == result.hasError) {
                this.$message({
                  message: result.errorText,
                  type: 'error',
                  duration: 0
                });
                return;
              }
              if (result.data != null && result.data.code != '200') {
                this.$message({
                  message: result.data.message,
                  type: 'error',
                  duration: 0
                });
                return;
              }
              this.pageShow = true
              this.study = result.data.data;
              this.study.showImage = false;

              this.tabs.arr.forEach(el => {
                if (el.id == 'image' || el.id == 'more') {
                  this.study.showImage = this.study.deviceType != 'US' && this.study.pacsStudies.length > 0;
                  el.hide = !this.study.showImage;
                }
              })
              if (this.study != undefined) {
                if (store.verifyPaymentStatusFlag != true) {   // 未开通患者收费模式时，默认允许浏览图像
                  this.study.displayEflimImageFlag = 1
                }
              }

              await this.compute2DViewerMode()
              await this.computeDisplayTip()
            })
        },

      async getHospitalInfoByToken() {
          //通过getStudyIdByToken 获取检查信息
           MessageProxy.getStudyIdByToken(this.studyID).then((result)=>{
            if (true == result.hasError) {
              this.$message({
                message: result.errorText,
                type: 'error',
                showClose: true,
              });
              return;
            }
            if (result.data != null && result.data.code != '200') {
              this.$message({
                message: result.data.message
              })
              return;
            }
            store.hospital.hospitalId = result.data.data.hospitalId;
            store.hospital.shortName = result.data.data.shortName;
            store.studyID = result.data.data.studyId;
            this.studyID = result.data.data.studyId;
             //为空，报错提示
             if (store.hospital.hospitalId == undefined || store.hospital.hospitalId == '' || store.hospital.shortName == undefined || store.hospital.shortName == '') {
               this.$message.error('未获取到医院信息，请完整复制分享链接打开');
               return;
             }
             MessageProxy.getHospitalInfo(store.hospital.shortName).then((result) => {
               if (true == result.hasError) {
                 this.$message.error(result.errorText);
                 return;
               }
               if (result.data != null && result.data.code != '200') {
                 this.$message.error(result.data.message);
                 return;
               }
                   if (result.data.data != null) {
                       store.hospital.id = result.data.data.id;
                       this.hospitalId = store.hospital.id;
                       this.showHistory = !!result.data.data.historyStudyListFlag;

                       if (result.data.data.image3dServiceUrl != null && result.data.data.image3dServiceUrl != '') {
                           store.hospital.image3dServiceUrl = result.data.data.image3dServiceUrl;
                           this.image3dUrl = store.hospital.image3dServiceUrl
                       }

                       if (result.data.data.enable3dFlag != null && result.data.data.enable3dFlag){
                           this.showImage3dButton = true;
                       }

                       if (result.data.data.enableFlag != undefined && 0 == result.data.data.enableFlag) {
                           store.hospital.enableFlag = false;
                       }

                       if (result.data.data.identityCheckMode != undefined && '' != result.data.data.identityCheckMode) {
                           store.hospital.identityCheckMode = result.data.data.identityCheckMode;
                       }
                       if (result.data.data.noPayTip != undefined && '' != result.data.data.noPayTip) {
                           store.hospital.noPayTip = result.data.data.noPayTip;
                       }

                       if (result.data.data.professionalFlag != undefined && '' != result.data.data.professionalFlag){
                           store.isProfessional = result.data.data.professionalFlag==1 ? true : false
                       }

                       if (result.data.data.verifyPaymentStatusFlag != undefined && '' != result.data.data.verifyPaymentStatusFlag){
                           store.verifyPaymentStatusFlag = result.data.data.verifyPaymentStatusFlag==1 ? true : false
                       } else {
                           store.verifyPaymentStatusFlag = false
                       }

                       if (result.data.data.useImageProInsteaded != undefined && '' != result.data.data.useImageProInsteaded){
                           store.useImageProInsteaded = result.data.data.useImageProInsteaded==1 ? true : false
                       } else {
                           store.useImageProInsteaded = false
                       }
                       if (result.data.data.maintenanceModeFlag != undefined && '' != result.data.data.maintenanceModeFlag){
                           store.maintenanceModeFlag = result.data.data.maintenanceModeFlag==1 ? true : false
                       } else {
                           store.maintenanceModeFlag = false
                       }

                       if (result.data.data.enableImageWhenReportUnaudit != undefined && '' != result.data.data.enableImageWhenReportUnaudit){
                           store.enableImageWhenReportUnaudit = result.data.data.enableImageWhenReportUnaudit==1 ? true : false
                       } else {
                           store.enableImageWhenReportUnaudit = false
                       }

                       this.hospital.enableFlag = store.hospital.enableFlag;
                       this.hospital.identityCheckMode = store.hospital.identityCheckMode;
                       this.hospital.isProfessional = store.isProfessional
                       this.hospital.useImageProInsteaded = store.useImageProInsteaded         // 使用专业2D，替代患者版2D
                       this.hospital.maintenanceModeFlag = store.maintenanceModeFlag           // 运维模式，图像浏览界面显示为”系统升级中...”
                       this.hospital.enableImageWhenReportUnaudit = store.enableImageWhenReportUnaudit    // 报告未审核时，是否允许浏览图像

                      this.init()
                   }
             })
          })

        },
        tabclick (id) {
          if (id !== 'image' && id !== 'more') {
            this.tabs.selected = id;
            this.needPay = false;
            return;
          }

          this.needPay = false;

          if (id === 'image' || id === 'more') {
            if(!this.goods.protocol) {
              this.getProtocol()
            }
            if (this.goods.arr.length === 0) {
              this.getGoodsInfo()
            }
            MessageProxy.getPayStatus({short_name: store.hospital.shortName,ris_study_id: this.studyID}).then((result)=>{
              if (result.hasError) {
                this.$message.error(result.errorText);
                return
              }
              if (result.data.code != '200') {
                this.$message.error(result.data.message);
                return
              }
              if (result.data.data.canPay && result.data.data.payOut && false == this.JSAPIPay) {
                this.$message.warning('请用微信浏览图像。');
                return
              }
              this.tabs.selected = id
              if (result.data.data.canPay && result.data.data.payOut) {
                this.needPay = true;
              }
            })
          }
        },
        getProtocol () { // 获取服务协议
          MessageProxy.getProtocol().then((result)=>{
            if (result.hasError) {
              this.$message.error(result.errorText);
              return;
            }
            this.goods.protocol = ''
            result.data.split(/[(\r\n)\r\n]+/).forEach((el) => {
              this.goods.protocol += '<p class="p">' + el + '</p>'
            })
          })
        },
        getGoodsInfo () { // 获取商品列表
          MessageProxy.getGoodsInfo(store.hospital.id).then((result)=>{
            if (true == result.hasError) {
              this.$message.error(result.errorText);
              return;
            }
            if (result.data != null && result.data.code != '200') {
              this.$message.error(result.data.message);
              return;
            }
            if (result.data.data != null && result.data.data.length) {
              this.goods.arr = result.data.data
              this.goods.selected = result.data.data[0].goods_id

              for (const goods of this.goods.arr) {
                if ('0' == goods.service_duration) {
                  goods.service_duration = '';
                } else {
                  let durationUnit = goods.service_duration.substring(goods.service_duration.length-1).toUpperCase()
                  let durationValue = goods.service_duration.substring(0, goods.service_duration.length-1)
                  if (durationUnit.endsWith('H') == true) {
                    goods.service_duration = durationValue + " 小时";
                  } else if (durationUnit.endsWith('D') == true) {
                    goods.service_duration = durationValue + " 天";
                  } else if (durationUnit.endsWith('W') == true) {
                    goods.service_duration = durationValue + " 周";
                  } else if (durationUnit.endsWith('M') == true) {
                    goods.service_duration = durationValue + " 个月";
                  } else if (durationUnit.endsWith('Y') == true) {
                    goods.service_duration = durationValue + " 年";
                  }
                }
              }
            }
          })
        },
        gotoImage() {
            this.tabclick('image');
        },
        closeView() {
            this.$emit("closeView");
            // this.$router.go(-1);
        },
        goBack() {
            this.$message.info("回退 检查");
            this.closeView();
        },
        openImage2dPro() {
            // history.pushState("imagepro", null);
            this.imageProShown = true;
            this.image3dShown = false;
            this.imageViewName = "专业医学影像";
        },
        openImage3d() {
            // history.pushState("image3d", null);
            this.imageProShown = false;
            this.image3dShown = true;
            this.imageViewName = "三维影像后处理";
        },
        closeImageFrame() {
            this.imageProShown = false;
            this.image3dShown = false;
        },

        compute2DViewerMode(){
            if (this.hospital.maintenanceModeFlag == true){
                this.TowDViewerMode = 0
                return
            }

            if (this.hospital.enableImageWhenReportUnaudit == false && this.study.studyStatus < 70){
                this.TowDViewerMode =  0
                return
            }

            if (this.study.displayEflimImageFlag == 1){
                if (this.hospital.useImageProInsteaded == true) {
                    this.TowDViewerMode =  2
                } else {
                    this.TowDViewerMode =  1
                }
            } else {
                this.TowDViewerMode =  0
            }
        },

        computeDisplayTip(){
            if (this.hospital.maintenanceModeFlag == true){
                this.displayTip = "系统升级中..."
                return
            }

            if (this.hospital.enableImageWhenReportUnaudit == false && this.study.studyStatus < 70){
                this.displayTip =  "报告审核中，请稍后再试..."
                return
            }

            if (this.study.displayEflimImageFlag == 1){
                if (this.hospital.useImageProInsteaded == true) {
                    this.displayTip =  ""
                } else {
                    this.displayTip =  ""
                }
            } else {
                this.displayTip =  store.hospital.noPayTip || "未缴纳此检查的电子胶片费，无法浏览图像。如需浏览图像，请联系就诊科室医生。"
            }
        }
    }
}
</script>

<style scoped>

/*PC端tab样式*/
@media (min-width: 768px) {

  .study-view-container {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }
  .title-container .tabs-container .tabs-box {
    flex-grow: 1;

    font-size: 24px;
    padding: 10px 0;

    display: flex;
    justify-content: space-around;
    align-items: stretch;
  }
  .title-container .tabs-container .tabs-box .tab-item {
    /* width: 25%; */
    display: flex;
    align-items: center;
  }
  .title-container .tabs-container .tabs-box .tab-item.active {
    color: #409eff;
    border-bottom: 2px solid #409eff;
  }
  .title-container .tabs-container .tabs-box .tab-item:hover{
    cursor: pointer;
  }
  .content-container {
    position: relative;
    height: calc(100% - 56px);
  }

  .study-info-view,
  .image-view,
  .film-view,
  .share-code-view {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    font-size: 4vmin;
    text-align: left;
    overflow-y: hidden;
  }
  .image-frame {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }


  .image-frame-box {
    position:fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
  .image-frame-box .title-container {
    height: 12vmin;
    background-color: #222;
    color: #ccc;
    display: flex;
    align-items: stretch;
  }
  .image-frame-box .title-container .back-icon {
    font-size: 6vmin;
    padding: 0 3vmin;
    display: flex;
    align-items: center;
  }
  .image-frame-box .title-container .back-text {
    font-size: 4vmin;
    margin-left: 3vmin;
  }
  .image-frame-box .title-container .back-icon:hover {
    cursor: pointer;
  }
  .image-frame-box .title-container .view-name {
    display: inline-block;
    display: flex;
    align-items: center;
  }
  .imagepro-frame,
  .image3d-frame {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: calc(100% - 12vmin);
  }
  .btn-image2d-pro,
  .btn-image3d {
    height: 150px;
    width: 40%;
  }

  .btn-image2d-pro {
    margin-top: 20vmin;
    margin-left: 10px;
  }

  .btn-image3d {
    margin-top: 10vmin;
  }

  .btn-image2d-pro .title,
  .btn-image3d .title {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .more-view .hint {
    color: red;
    font-size: 20px;
  }


}

/*移动端样式*/
@media (max-width: 767px) {

  .study-view-container {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }
  .title-container {
    height: 12vmin;

    display: flex;
    align-items: stretch;
  }
  .title-container .back-icon {
    font-size: 6vmin;
    padding: 0 3vmin;

    display: flex;
    align-items: center;
  }
  .title-container .back-icon:active {
    background-color: #eaeaea;
  }
  .title-container .tabs-container {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
  }
  .title-container .tabs-container .tabs-box {
    flex-grow: 1;

    font-size: 4vmin;
    padding-bottom: 2vmin;

    display: flex;
    justify-content: space-around;
    align-items: stretch;
  }
  .title-container .tabs-container .tabs-box .tab-item {
    /* width: 25%; */
    display: flex;
    align-items: center;
  }
  .title-container .tabs-container .tabs-box .tab-item.active {
    color: #409eff;
    border-bottom: 2px solid #409eff;
  }
  /* .tabs-container .tabs-separator {
      width: 100%;
      height: 2px;
  }
  .tabs-container .tabs-separator .separator-line{
      width: 25%;
      height: 2px;
      background-color: #409eff;
      transition: transform .3s cubic-bezier(0.65, 0.05, 0.36, 1);
  } */

  .content-container {
    position: relative;
    height: calc(100% - 12vmin);
  }

  .study-info-view,
  .image-view,
  .film-view,
  .share-code-view {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    font-size: 4vmin;
    text-align: left;
    overflow-y: hidden;
  }
  .image-view .image-view-back {
    position: absolute;
    left: 3vmin;
    bottom: 3vmin;
    z-index: 2;
  }
  .study-view-container .hide {
    display: none;
  }

  .image-frame {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
  .notJSAPIPay{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    padding-top: 100px;
  }
  .sen-button {
    box-sizing: border-box;
    background-color: #c3dffc;
    color: #fff;
    border: 1px solid #409eff;
    text-align: center;
    font-weight: 500;
    padding: 2vmin 10vmin;
    border-radius: 1.2vmin;
    outline: 0;
  }
  .sen-button:focus {
    background-color: #78bafc;
  }
  .btn-image2d-pro,
  .btn-image3d {
    height: 20vmin;
    width: 80%;
  }

  .btn-image2d-pro {
    margin-top: 20vmin;
    margin-left: 10px;
  }

  .btn-image3d {
    margin-top: 10vmin;
  }

  .btn-image2d-pro .title,
  .btn-image3d .title {
    font-size: 4.5vmin;
    margin-bottom: 3vmin;
  }

  .more-view .hint {
    color: red;
  }




  .image-frame-box {
    position:fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
  .image-frame-box .title-container {
    height: 12vmin;
    background-color: #222;
    color: #ccc;
    display: flex;
    align-items: stretch;
  }
  .image-frame-box .title-container .back-icon {
    font-size: 6vmin;
    padding: 0 3vmin;
    display: flex;
    align-items: center;
  }
  .image-frame-box .title-container .back-text {
    font-size: 4vmin;
    margin-left: 3vmin;
  }
  .image-frame-box .title-container .back-icon:active {
    background-color: #eaeaea;
  }
  .image-frame-box .title-container .view-name {
    display: inline-block;
    display: flex;
    align-items: center;
  }

  .imagepro-frame,
  .image3d-frame {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: calc(100% - 12vmin);
  }
  .pay-view {
    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .auth-view {
    position:fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    backdrop-filter: blur(6px);
    background-image: linear-gradient(rgba(236, 245, 255, 0.5) 5%, rgba(255, 255, 255, 1) 15%);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .auth-view .input-box {
    background-color: rgba(236, 245, 255, 1);
    padding: 8vmin;
    margin: 1vmin;
    border-radius: 1vmin;
    /* border: solid #409eff 1px; */
  }
  .auth-view .auth-hint {
    font-size: 4.5vmin;
    margin-bottom: 5vmin;
  }
  .auth-view .resident-id {
    font-size: 6.5vmin;
    text-align: center;
    width: 30vmin;
  }
  .auth-view .patient-ming {
    font-size: 6.5vmin;
  }
  .auth-view .patient-xing {
    font-size: 6.5vmin;
    width: 10vmin;
    text-align: center;
  }
  .auth-view .input-box .btn-auth {
    margin-top: 10vmin;
    width: 50vmin;
  }
  .sc-input {
    box-sizing: border-box;
    /* background-color:#fff; */
    color:#606266;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #666;
    height: 10vmin;
    line-height: 10vmin;
    padding: 0;
    outline: none;
  }
  .sc-input:focus {
    border-color: #409eff;
  }
  .auth-view .test-run {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2vmin;
    color: red;
    font-size: 3vmin;
  }
  .displayEflimImageFlagTip{
    padding: 5vmin;
    background-color: #fef0f0;
    color: #f56c6c;
    margin: 5vmin 2vmin;
  }
  .el-button.is-disabled .hint,
  .el-button.is-disabled:focus .hint,
  .el-button.is-disabled:hover .hint{
    opacity: 0.5;
  }

}




/* PC端导航条字体太大影响美观，通过媒体查询单独调整一下尺寸 */
@media (min-width: 600px) {
  .image-frame-box .title-container {
    height: 50px;
  }
  .image-frame-box .title-container .back-icon {
    font-size: 18px;
    padding: 0 1vmin;
  }
  .image-frame-box .title-container .back-text {
    font-size: 18px;
    margin-left: 1vmin;
  }
  .image-frame-box .title-container .view-name {
    margin-left: 5vmin;
    font-size: 18px;
  }
  .imagepro-frame, .image3d-frame {
    height: calc(100% - 50px);
  }

}

</style>