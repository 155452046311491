
<template>
  <div class="report-view-container">
    <el-container style="height: 100%;">
      <el-header style="height: auto;">
        <div class="title-container">
          <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i>
            <span class="view-name">返回</span></div>
        </div>
      </el-header>
      <el-main style="position: relative; padding: 0">
<!--        <div v-html="content"></div>-->
        <div class="tips">温馨提示：智能解读仅作为您的病情参考，不可完全替代医生诊断，请结合临床症状和检查结果，进一步明确诊断。</div>
        <div class="report-cont">
          <div class="ai-header">
            <div v-if="thinking" class="loading"><img src="../../assets/ai-made.gif"/> 智能解读中…</div>
            <div v-if="!thinking" class="over" ><img src="../../assets/ai-head.svg"/> 智能解读如下：</div>
          </div>
          <div class="ai-cont" v-html="content">
            <!--    生成内容存放地方      -->
<!--            <ol>-->
<!--              <li>啊束带结发阿斯顿福建看</li>-->
<!--            </ol>-->
          </div>


        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import { fetchEventSource } from "@microsoft/fetch-event-source";
import { marked } from "marked";

export default {
  name: 'ReportAnalyzeView',
  props: {
    study: {}
  },
  data() {
    return {
      thinking: true,
      content: ""
    };
  },
  created() {
    this.analyzeReport()
  },
  watch: {
    content(newVal, oldVal) {
      console.log(newVal, oldVal)
    }
  },
  methods: {
    async analyzeReport() {
      let prompt = "我是一名患者，我有一份报告，请以我能理解的方式解读一下。以下是报告内容：\n" + this.study.reportDesc + '\n' + this.study.reportDiagnose
      // let q = {
      //   model: "deepseek-r1:32b",
      //   prompt: "我是一名患者，我有一份报告，请以我能理解的方式解读一下。以下是报告内容：\n" + this.study.reportDesc + '\n' + this.study.reportDiagnose,
      //   stream: true
      // }
      let s = ""
      let that = this
      await fetchEventSource('https://report-analyze.myfilm.cc/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: prompt,
        onopen(response) {
          if(response.ok) return
          throw new Error("连接失败")
        },
        onmessage(evt) {
          if(that.thinking) { that.thinking = false; }
          s += evt.data.replaceAll('&#32;', ' ').replaceAll('&#10;', '\n')
          console.log(evt.data)
          console.log(this)
          that.content = marked.parse(s)
        },
        onclose() {},
        onerror(err) {
          console.error(err)
          throw err
        }
      })
    },
    closeView() {
      this.$emit('update:analyzeViewVisible', false);
    }
  }
}
</script>

<style scoped>
/*PC端tab样式*/
@media (min-width: 768px) {
  .el-header{
    padding: 0;
    height: auto;
    background: #2c3e50;
  }

  .back-icon{
    color: #cccccc;
  }
  .report-view-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #f6f6f6;
    overflow-y: auto;
    z-index: 4;
  }
  .title-container {
    height: 60px;
    display: flex;
    align-items: stretch;
  }
  .title-container .back-icon {
    font-size: 30px;
    padding: 0 10px;
    display: flex;

    align-items: center;
  }
  .title-container .back-icon:hover{
    cursor: pointer;
  }
  .title-container .view-name {
    font-size: 24px;
    margin-left: 15px;
  }
  .title-container .back-icon:active {
    background-color: #eaeaea;
  }

  .tips{
    background: #FDFFEA;
    font-size: 14px;
    color: #715F19;
    padding:5px 10px;
    border-bottom: 1px solid #E8E2CF;
  }

  .report-cont{
    padding: 10px;
  }
  .report-cont ol{
    padding: 0 0 0 20px;
  }

  .report-cont ul{
    padding: 0 0 0 20px;
  }

  .ai-header{
    background: linear-gradient(rgba(64,158,255,0.3),rgba(174,214,255,.2));
    padding: 5px;
    border-radius: 50px;
    height: auto;
    font-size: 14px;
    width: 200px;

  }
  .ai-header .loading{
    display: flex;
    align-items: center;

  }
  .ai-header .over{
    display: flex;
    align-items: center;

  }
  .ai-header img{
    height: 24px;
    margin-right: 10px;
  }
  .ai-cont{
    font-size: 16px;
    padding: 0 0 0 20px;
  }

  .report-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 12vmin;
    bottom: 0;
    background-color: #f6f6f6;
    font-size: 4vmin;
    text-align: left;
    overflow: auto;
  }
  .report-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
  .hint-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hint-container .hint-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
  }
  .roport-size{
    max-width: 1024px;
    margin: 0 auto;
  }
}

/*移动端样式*/
@media (max-width: 767px) {

  .report-view-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #f6f6f6;
    overflow-y: auto;
    z-index: 4;
  }
  .el-header{
    background: #2c3e50;
  }
  .back-icon{
    color: #cccccc;
  }
  .title-container {
    height: 12vmin;
    display: flex;
    align-items: stretch;

  }
  .title-container .back-icon {
    font-size: 6vmin;
    display: flex;
    align-items: center;
  }
  .title-container .view-name {
    font-size: 4vmin;
    margin-left: 3vmin;
  }
  .title-container .back-icon:active {
    background-color: #eaeaea;
  }
  .tips{
    background: #FDFFEA;
    font-size: 12px;
    color: #715F19;
    padding:5px 10px;
    border-bottom: 1px solid #E8E2CF;
  }

  .report-cont{
    padding: 10px;
  }
  ::v-deep .report-cont ol{
    padding: 0 0 0 20px;
  }

  ::v-deep .report-cont ul{
    padding: 0 0 0 20px;
  }

  ::v-deep .report-cont p{
    padding: 0 0 0 0;
  }

  .ai-header{
    background: linear-gradient(rgba(64,158,255,0.3),rgba(174,214,255,.2));
    padding: 5px;
    border-radius: 50px;
    height: auto;
    font-size: 14px;

  }
  .ai-header .loading{
    display: flex;
    align-items: center;

  }
  .ai-header .over{
    display: flex;
    align-items: center;

  }
  .ai-header img{
    height: 24px;
    margin-right: 10px;
  }
  .ai-cont{
    padding: 0 0 0 0px;
  }

  .report-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 12vmin;
    bottom: 0;
    background-color: #f6f6f6;
    font-size: 4vmin;
    text-align: left;
    overflow: auto;
  }
  .report-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
  .hint-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hint-container .hint-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>
